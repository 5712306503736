<template>
  <form class="dg-form">
    <ValidationObserver ref="validation">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <DgPortalInput
          class="dg-col-12"
          v-model="form.currentPassword"
          type="password"
          :label="$t('OLD_PASSWORD')"
          :placeholder="$t('OLD_PASSWORD')"
          :error="errors[0]"
        />
      </ValidationProvider>
      <ValidationProvider
        name="newPassword"
        rules="required"
        v-slot="{ errors }"
      >
        <DgPortalInput
          class="dg-col-12"
          v-model="form.newPassword"
          type="password"
          :label="$t('NEW_PASSWORD')"
          :placeholder="$t('NEW_PASSWORD')"
          :error="errors[0]"
        />
      </ValidationProvider>
      <ValidationProvider
        rules="required|confirm:@newPassword"
        v-slot="{ errors }"
      >
        <DgPortalInput
          class="dg-col-12"
          v-model="form.confirmNewPassword"
          type="password"
          :label="$t('CONFIRM_YOUR_PASSWORD')"
          :placeholder="$t('CONFIRM_YOUR_PASSWORD')"
          :error="errors[0]"
        />
      </ValidationProvider>
    </ValidationObserver>
  </form>
</template>
<script>
import { DgPortalInput } from "@engenharia/dashgoo-ui";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "FormDashboard",
  components: {
    DgPortalInput,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    form: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  }),
  methods: {
    onSubmit() {
      this.$notifySuccess({
        text: "PASSWORD_SUCCESSFULLY_CHANGED",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-form {
  > span {
    width: 100%;
  }
}

.dg-form {
  @media (min-width: 768px) {
    margin-right: -16px;
    margin-left: -16px;
  }
}
</style>
